import CmsModuleList from '@/cmsComponents/CmsModuleList';
import CMSContainer from '@/modules/CMSContainer';
import { DynamicPageData } from '@/types/cms';
import { NextSeo } from 'next-seo';
import React from 'react';

import { Typography } from '@material-ui/core';

import styles from './style.module.scss';

export const DynamicPage: React.FC<DynamicPageData> = ({ data, pageData }: DynamicPageData) => {
  const defaultTitle = 'Doctor Job Search';
  const defaultDescription = 'Discover the right doctor job for you in Australia or New Zealand';

  // The Cover component adds a h1 heading,
  // but no other components do, so we should add one manually
  const thereShouldBeAHeadingAdded =
    data.length > 0 && data[0].__component !== 'cover-collection.single-cover-collection';

  return (
    <CMSContainer>
      <NextSeo
        canonical={pageData.canonical_url}
        title={pageData.meta_title || defaultTitle}
        description={pageData.meta_description || defaultDescription}
        noindex={!pageData.index}
        nofollow={!pageData.index}
        openGraph={{
          title: pageData.meta_title || defaultTitle,
          description: pageData.meta_description || defaultDescription,
        }}
      />
      {thereShouldBeAHeadingAdded && (
        <div className={styles.heading}>
          <Typography variant="h3" component="h1">
            {pageData.title}
          </Typography>
        </div>
      )}
      <CmsModuleList modules={data} />
    </CMSContainer>
  );
};

export default DynamicPage;
