import { get404Page } from '@/utils/browserCMSAccess';
import React, { useEffect, useState } from 'react';
import { Module } from 'types/cms';

import { DynamicPage } from 'components/CMS/DynamicPage';

const Custom404 = () => {
  const [pageData, setPageData] = useState<Module[]>([]);

  useEffect(() => {
    const get404page = async () => {
      const response = await get404Page();
      setPageData(() => response.modules || []);
    };

    get404page();
  }, []);

  return (
    <DynamicPage
      data={pageData}
      pageData={{ title: '404', meta_title: '404', meta_description: '404', redirect_url: null }}
    />
  );
};

export default Custom404;
